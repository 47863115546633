import { useState, useEffect } from "react";
import { Box, useTheme, Modal, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosInstance from "../../api/axios";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import { useSnackbar } from "notistack";

const AUCTION_URL = "api/bidit/biditData";
const AUCTION_DELETE_URL = "api/bidit/auction";

const BiditSearch = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [auctionToDelete, setAuctionToDelete] = useState(null); // Store the selected auction

  const [openAuctionModal, setOpenAuctionModal] = useState(false);
  const [selectedAuction, setSelectedAuction] = useState(null);

  const [galleryImages, setGalleryImages] = useState([]); // Array of images for the selected row
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Index of the currently displayed image

  const { enqueueSnackbar } = useSnackbar();

  const deleteAuctionPost = async (barterMarketId) => {
    setIsDeleting(true);
    try {
      const response = await axiosInstance.delete(
        `${AUCTION_DELETE_URL}/${barterMarketId}`
      );
      console.log(response.data.message);

      // Show success toast
      enqueueSnackbar("Auction post deleted successfully!", {
        variant: "success",
      });

      setOpenAuctionModal(false); // Close the modal after deletion
      setOpenConfirmModal(false); // Close the confirmation modal
      getBiditData(); // Refresh the data grid
    } catch (error) {
      console.error("Failed to delete auction post:", error);

      // Show error toast
      enqueueSnackbar("Failed to delete auction post. Please try again.", {
        variant: "error",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const validateImageUrl = async (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true); // Image loaded successfully
      img.onerror = () => resolve(false); // Image failed to load
      img.src = url;
    });
  };

  const handleOpenGallery = async (images) => {
    const validImages = [];
    for (const image of images) {
      const isValid = await validateImageUrl(image.image_url);
      if (isValid) validImages.push(image.image_url);
    }
    setGalleryImages(validImages);
    setCurrentImageIndex(0); // Start with the first valid image
    setOpenImageModal(true); // Open the modal
  };

  const getBiditData = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(AUCTION_URL, {
        params: { page },
      });
      setData(response.data.data);
      console.log("response.data.data", response.data.data);
      setPagination(response.data.pagination);
    } catch (e) {
      console.error("Error fetching bidit data:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBiditData();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: "user_photo",
      headerName: "User Photo",
      width: 100,
      renderCell: (params) => (
        <Avatar
          src={params.row.user.photo_url}
          alt={params.row.user.username}
        />
      ),
      sortable: false, // If you don't want to sort by photo
      filterable: false, // If filtering by photo doesn't make sense
    },
    {
      field: "username",
      headerName: "Username",
      width: 200,
      renderCell: (params) => (
        <span style={{ color: "inherit", fontWeight: "bold" }}>
          {params.row.user.username}
        </span>
      ),
    },
    {
      field: "images",
      headerName: "Images",
      width: 100,
      renderCell: (params) => (
        <ImageSearchOutlinedIcon
          sx={{
            cursor: "pointer",
            fontSize: 30,
            color: theme.palette.secondary.main,
          }}
          onClick={() => {
            handleOpenGallery(params.row.images); // Pass the images array to the handler
          }}
        />
      ),
    },
    {
      field: "auction",
      headerName: "Auction Details",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setSelectedAuction(params.row); // Set the entire row as the selected auction
            setOpenAuctionModal(true); // Open the modal
          }}
        >
          View Details
        </Button>
      ),
    },
    
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        // Safely access auction.status
        const status = params.row.auction?.status || "N/A";
        // Return plain text, a <Chip/>, or any component
        return status;
      },
    },
    {
      field: "start_time",
      headerName: "Started",
      width: 150,
      renderCell: (params) => 

      moment(params.row.auction?.start_time).format("YYYY-MM-DD"),
      },
    {
      field: "end_time",
      headerName: "Ends",
      width: 150,
      renderCell: (params) => 
        moment(params.row.auction?.end_time).format("YYYY-MM-DD"),

      },
      {
        field: "updated_at",
        headerName: "Updated At",
        width: 150,
        renderCell: (params) =>
          moment(params.row.updated_at).format("YYYY-MM-DD"),
      },
  ];


  const rows = data.map((row) => ({
    ...row,
    user: row.user || {},
    images: row.images || [],
    auction: row.auction || {},
  }));

  return (
    <Box m="20px">
      <Header title="Bidit™" subtitle="Bidit™ Search" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={rows}
          getRowId={(row) => row._id}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          pagination
          pageSizeOptions={[10, 20, 50]}
          rowCount={pagination.totalCount}
          loading={loading}
          onPageChange={(newPage) => getBiditData(newPage + 1)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light"
                  ? colors.primary[100]
                  : colors.primary[700],
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.primary.main,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.default,
            },
          }}
        />
      </Box>
      {/* Auction Image Modal */}
      <Modal
        open={openImageModal}
        onClose={() => setOpenImageModal(false)}
        aria-labelledby="modal-gallery-title"
        aria-describedby="modal-gallery-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {galleryImages.length > 0 && (
            <>
              <img
                src={galleryImages[currentImageIndex]}
                alt={`${currentImageIndex + 1}`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "80vh",
                  objectFit: "contain",
                }}
              />

              {/* Navigation Buttons */}
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() =>
                    setCurrentImageIndex(
                      (currentImageIndex + galleryImages.length - 1) %
                        galleryImages.length
                    )
                  }
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    setCurrentImageIndex(
                      (currentImageIndex + 1) % galleryImages.length
                    )
                  }
                >
                  Next
                </Button>
              </Box>

              {/* Close Button */}
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenImageModal(false)}
                >
                  Close
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      {/* Auction Details Modal */}
      <Modal
  open={openAuctionModal}
  onClose={() => setOpenAuctionModal(false)}
  aria-labelledby="auction-modal-title"
  aria-describedby="auction-modal-description"
>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      maxHeight: "85vh",
      bgcolor: "background.paper",
      boxShadow: 24,
      borderRadius: 3,
      overflowY: "auto",
      p: 4,
    }}
  >
    {selectedAuction && (
      <>
        {/* Modal Header */}
        <Box
          sx={{
            borderBottom: "2px solid",
            borderColor: "divider",
            pb: 2,
            mb: 3,
          }}
        >
          <Typography
            variant="h5"
            id="auction-modal-title"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              color: "secondary",
            }}
          >
            Auction Details
          </Typography>
        </Box>

        {/* Auction Information */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", mb: 2, color: "secondary.main" }}
          >
            General Information
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 2fr",
              rowGap: 2,
              columnGap: 4,
            }}
          >
            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Status:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction.auction.status}
            </Typography>
            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Auction owner:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction.user.username}
            </Typography>

            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Category:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction.barter_categories}
            </Typography>

            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Title:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction.in_search_of}
            </Typography>

            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Description:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction.description}
            </Typography>
          </Box>
        </Box>

        {/* Auction Details */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", mb: 2, color: "secondary.main" }}
          >
            Auction Details
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 2fr",
              rowGap: 2,
              columnGap: 4,
            }}
          >
            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Highest Bid:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction.auction.current_highest_bid || "N/A"}
            </Typography>
            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Highest Bidder ID:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction.auction.current_highest_bidder_id || "N/A"}
            </Typography>

            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Start Time:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {moment(selectedAuction.auction.start_time).format("MMM DD, YYYY")}
            </Typography>

            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              End Time:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {moment(selectedAuction.auction.end_time).format("MMM DD, YYYY")}
            </Typography>

            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Minimum Bid:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              ${selectedAuction.auction.min_bid || 0}
            </Typography>

            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Buy It Now Price:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              ${selectedAuction.auction.buy_it_now_price || "N/A"}
            </Typography>

            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Bid Count:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction.auction.bid_count || 0}
            </Typography>
            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Views:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction.no_of_views || 0}
            </Typography>

            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Charity:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction.auction.is_charity ? "Yes" : "No"}
            </Typography>
            <Typography sx={{ fontWeight: "bold", color: "neutral.light" }}>
              Auction ID:
            </Typography>
            <Typography sx={{ color: "secondary.main" }}>
              {selectedAuction._id}
            </Typography>
          </Box>
        </Box>

        {/* Modal Footer */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            borderTop: "2px solid",
            borderColor: "divider",
            pt: 3,
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={() => setOpenAuctionModal(false)}
            sx={{
              backgroundColor: "error.main",
              color: "common.white",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "error.dark",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setAuctionToDelete(selectedAuction._id);
              setOpenConfirmModal(true);
            }}
            disabled={isDeleting}
            sx={{
              backgroundColor: "black",
              color: "error.main",
              border: "2px solid",
              borderColor: "error.main",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "error.main",
                color: "black",
                border: "2px solid black",
              },
            }}
          >
            {isDeleting ? "Deleting..." : "Delete Auction"}
          </Button>
        </Box>
      </>
    )}
  </Box>
</Modal>

      <Modal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        aria-labelledby="confirm-delete-title"
        aria-describedby="confirm-delete-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography
            id="confirm-delete-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Confirm Deletion
          </Typography>
          <Typography id="confirm-delete-description" sx={{ mb: 3 }}>
            Are you sure you want to delete this auction post? This action
            cannot be undone.
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpenConfirmModal(false)}
              sx={{
                color: "text.primary",
                borderColor: "text.primary",
                "&:hover": {
                  backgroundColor: "error.light",
                  borderColor: "text.primary",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => deleteAuctionPost(auctionToDelete)} // Trigger the delete action
              disabled={isDeleting}
              sx={{
                backgroundColor: "black",
                color: "error.main",
                border: "2px solid",
                borderColor: "error.main",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "error.main",
                  color: "black",
                  border: "2px solid black",
                },
              }}
            >
              {isDeleting ? "Deleting..." : "Delete Auction"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default BiditSearch;
