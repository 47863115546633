import { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Grid,
  Button,
  Typography,
  Paper,
  TextField,
} from "@mui/material";
import { tokens } from "../../theme";
import { Send, SpeakerNotes, ArrowLeft } from "@mui/icons-material";
import Header from "../../components/Header";
import ReactPlayer from "react-player";
import axiosInstance from "../../api/axios";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ChatContainer } from "../../styles/Container.Styled";
import phlokkAvatar from "../../images/loginLogo_2023.png";
import { userName } from "../../services/jwt";

const SINGLE_REPORT_URL = "api/support/getSingleReport/";
const ADD_RESPONSE_TO_REPORT_URL = "api/support/addResponseToReport";

const RespondReport = () => {
  const username = userName();
  const navigate = useNavigate();
  const [report, setReport] = useState({});
  const { id } = useParams();
  const [input, setInput] = useState("");

  const handleSend = () => {
    if (input.trim() !== "") {
      setReport(
        (prev) =>
          (prev = {
            ...report,
            responses: [
              ...report?.responses,
              {
                id: prev.responses.length,
                response: input,
                responder: username,
              },
            ],
          })
      );
      addResponseToReport();
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const addResponseToReport = async () => {
    await axiosInstance
      .post(ADD_RESPONSE_TO_REPORT_URL, {
        userId: report.user_id,
        reportId: id,
        response: input,
        added_by: username,
      })
      .then((res) => {
        setInput("");
      });
  };

  const getSingleReport = async () => {
    await axiosInstance.get(SINGLE_REPORT_URL + id).then((res) => {
      setReport(res.data[0][0]);
    });
  };

  useEffect(() => {
    getSingleReport();
  }, []);

  // const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px" p="45px">
      <Header title="Support Center" subtitle="Video Reports" />
      <ChatContainer>
        <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
          <Box sx={{ pb: 2, display: "flex", justifyContent: "flex-start" }}>
            <Button
              startIcon={<ArrowLeft />}
              style={{
                borderColor: "#353b48",
                borderWidth: 1,
                borderStyle: "solid",
                color: "#fff",
              }}
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              back
            </Button>
          </Box>
          <Message message={report?.message} issue={true} report={report} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {report?.images?.map((image) => (
              <a href={image.url} target="_blank" rel="noreferrer"  key={image._id}>
                <img
                  src={image.url}
                  alt="avatar"
                  style={{
                    width: 150,
                    height: 150,
                    marginRight: 15,
                    marginTop: 15,
                  }}
                />
              </a>
            ))}
          </Box>
          {report?.responses?.map((message) => (
            <Message
              key={message._id}
              message={message}
              issue={false}
              report={report}
            />
          ))}
        </Box>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  pb: 2,
                  pt: 2,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Link to={"/knowledge"}>
                  <Button
                    startIcon={<SpeakerNotes />}
                    color="secondary"
                    variant="outlined"
                  >
                    Responses
                  </Button>
                </Link>
              </Box>

              <TextField
                maxRows={3}
                minRows={3}
                inputProps={{ maxLength: 1500 }}
                multiline
                fullWidth
                placeholder="Response..."
                value={input}
                onChange={(e) => handleInputChange(e)}
              />
              <Grid sx={{ pt: 3 }} item xs={2}>
                <Button
                  sx={{ pr: 3, pl: 3 }}
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={() => handleSend()}
                >
                  Send
                </Button>
                
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ChatContainer>
    </Box>
  );
};

const Message = ({ message, issue, report }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  return (
    <Box
      sx={{
        backgroundColor: "#0c101b",
        display: "flex",
        justifyContent: issue ? "flex-start" : "flex-end",
        p: 2,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          backgroundColor: "#0c101b",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          borderColor: issue ? "#c2c2c2" : "#00CEC9",
          borderWidth: 1.5,
          borderStyle: "solid",
          textAlign: "left",
          width: 400,
        }}
      >
        {issue ? (
          <Avatar width={60} src={report.photo_url} />
        ) : (
          <Avatar width={60} src={phlokkAvatar} />
        )}
        <Typography pt={3} variant="body1">
          {issue ? message : message.response}
        </Typography>
        <Typography pt={3} variant="body1">
          <Typography sx={{ color: colors.greenAccent[500] }}>
            {!issue && message.responder ? "Employee ID" : "User ID:"}{" "}
          </Typography>
          {!issue && message.responder ? message.responder : report.creator}
        </Typography>
        <Typography pb={1} variant="body1">
          {issue
            ? moment(report.created_at).format("YYYY-MM-DD HH:MM")
            : moment(message.created_at).format("YYYY-MM-DD HH:MM")}
        </Typography>

        <Box>
        {report.type === "video" &&  !message.responder ? (
            <ReactPlayer
              url={report?.url}
              controls={true}
              width={"100%"}
              height={"100%"}
            />
          ) : null}
        </Box>
      </Paper>
    </Box>
  );
};

export default RespondReport;
