import { useState, useEffect } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/MockData";

import {HighlightOff, DownloadOutlined, Flag, Announcement, ManageAccounts, Videocam, Support} from '@mui/icons-material';
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import SecurityLineChart from "../../components/SecurityLineChart";
import axiosInstance from "../../api/axios";
import { userName } from "../../services/jwt";
import { motion } from "framer-motion";

const REPORTS_URL = "api/support/securityVideoReportData";
const CLOSED_REPORTS_URL = "api/support/closedSecurityReportsCount";
const MotionBox = motion(Box);

const SecurityDashboard = () => {
  const theme = useTheme();
  
  const colors = tokens(theme.palette.mode);
  const username = userName();
  const [openReportsCount, setOpenReportsCount] = useState([]);
  const [totalReportsCount, setTotalReportsCount] = useState([]);
  const [closedReportsCount, setClosedReportsCount] = useState([]);
  const [videoReportsCount, setVideoReportsCount] = useState([]);
  const [creatorReportsCount, setCreatorReportsCount] = useState([]);
  const [supportReportsCount, setSupportReportsCount] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  //  REPORTS_URL
  useEffect(() => {
    // Combine both fetch calls using Promise.all
    const fetchData = async () => {
      try {
        const [securityRes, closedRes] = await Promise.all([
          axiosInstance.get(REPORTS_URL),
          axiosInstance.get(CLOSED_REPORTS_URL),
        ]);

        // 1) Handle securityRes (REPORTS_URL) data
        const securityData = securityRes.data || [];
        const reportsCount = securityData.filter((item) => item._id !== null)?.length || 0;
        const closedCount = securityData.filter((item) => item.status === "closed")?.length || 0;
        const openCount = securityData.filter((item) => item.status === "open")?.length || 0;
        const videoReportCount = securityData.filter((item) => item.type === "video")?.length || 0;
        const creatorReportCount = securityData.filter((item) => item.type === "creator")?.length || 0;
        const supportReportCount = securityData.filter((item) => item.type === "support")?.length || 0;

        setClosedReportsCount(closedCount);
        setOpenReportsCount(openCount);
        setTotalReportsCount(reportsCount);
        setVideoReportsCount(videoReportCount);
        setCreatorReportsCount(creatorReportCount);
        setSupportReportsCount(supportReportCount);

        // 2) Handle closedRes (CLOSED_REPORTS_URL) data
        const closedData = closedRes.data || [];
        const allClosed = closedData.filter((item) => item._id !== null)?.length || 0;

        // If you want to override closedReportsCount with this new result
        setClosedReportsCount(allClosed);

        // 3) Now that both fetches are done, set isDataLoaded
        setIsDataLoaded(true);
      } catch (err) {
        console.log("error fetching data =>", err);
      }
    };

    fetchData();
  }, []);

  // 4) Conditionally render a loading view or the animated dashboard
  if (!isDataLoaded) {
    return (
      <Box m="20px">
        <Box
  m="20px"
  display="flex"
  justifyContent="center"
  alignItems="center"
  height="75vh" // or any desired height
>
  <Typography variant="h5">Loading Security dashboard...</Typography>
</Box>
      </Box>
    );
  }


  return (

    <MotionBox
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      layout={false}
      m="20px"
    >
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Security" subtitle={`Welcome back, ${username}`} />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        sx={{
          display: {xs: 'flex', md: 'grid'},
          gridTemplateColumns: 'repeat(12, 1fr)',
          gridAutoRows: 'minmax(150px, auto)',
          flexDirection: 'column',
        }}
          gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={totalReportsCount}
            subtitle="Total Reports"
            progress="0.75"
            increase="+14%"
            icon={
              <Flag
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={openReportsCount}
            subtitle="Open Reports"
            progress="0.80"
            increase="+43%"
            icon={
              <Announcement
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={closedReportsCount}
            subtitle="Reports Closed"
            progress="0.30"
            increase="+5%"
            icon={
              <HighlightOff
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={creatorReportsCount}
            subtitle="Creator Reports"
            progress="0.75"
            increase="+14%"
            icon={
              <ManageAccounts
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={videoReportsCount}
            subtitle="Video Reports"
            progress="0.50"
            increase="+21%"
            icon={
              <Videocam
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={supportReportsCount}
            subtitle="Support Reports"
            progress="0.80"
            increase="+43%"
            icon={
              <Support
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 3 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
               Reports Handled
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {totalReportsCount}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlined
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <SecurityLineChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Reports
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                  
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
              sx={{
                backgroundColor: "#0c101b",
                color: colors.greenAccent[500],
                border: 1,
                borderColor: '#00CEC9',

              }}
                // backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {/* ${transaction.cost} */}
                resolved
              </Box>
            </Box>
          ))}
        </Box>
        
        {/* ROW 3 */}
      </Box>
      </MotionBox>
  );
};

export default SecurityDashboard;