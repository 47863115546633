import { useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  BoxContainer,
  LoginBox,
  StyledTextField,
} from "../../styles/Container.Styled";
import { Button, Typography, Box } from "@mui/material";
import phlokkLogo from "../../images/phlokk_logo.svg";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axios";
import Alert from "@mui/material/Alert";
import jwtDecode from "jwt-decode";

const LOGIN_URL = "api/auth/login";
const VERIFY_2FA_URL = "api/security/verify";
const GENERATE_QR_CODE_URL = "api/security/generate";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setErrorMessage] = useState(false);

  const [userId, setUserId] = useState(null); // Store user ID for 2FA
  const [show2FA, setShow2FA] = useState(false); // Show 2FA flow
  const [qrCode, setQrCode] = useState(""); // Store the QR code URL
  const [token, setToken] = useState(""); // Store 2FA token from the user
  const [verificationError, setVerificationError] = useState(false); // Show 2FA errors
  const [userRole, setUserRole] = useState(null); // Role state (e.g., admin, user)
  const [username, setUsername] = useState("");

  const errorMessage = () => {
    setErrorMessage(true);
  };

  function resetTextInput() {
    setEmail("");
    setPassword("");
  }

  const handleToken = (token) => {
    try {
      const decoded = jwtDecode(token);

      if (decoded.role) {
        setUserRole(decoded.role); // Update role in app state
      }

      if (decoded.username) {
        setUsername(decoded.username); // Update username in app state
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Submitting login request:", { email, password });
      const response = await axiosInstance.post(LOGIN_URL, { email, password });
      console.log("Login response:", response.data);
  
      if (response.data.require2FA) {
        console.log("2FA is required for this user.");
        setUserId(response.data.userId);
  
        if (!response.data.hasTOTPSecret) {
          // If the user has not set up 2FA, show the QR code
          const qrResponse = await axiosInstance.get(
            `${GENERATE_QR_CODE_URL}?userId=${response.data.userId}`
          );
          console.log("QR Code response:", qrResponse.data);
  
          setQrCode(qrResponse.data.qrCode);
          setShow2FA(true);
        } else {
          // If the user has already set up 2FA, directly prompt for the 6-digit code
          setShow2FA(true);
        }
      } else {
        console.log("2FA not required. Proceeding to dashboard.");
        localStorage.setItem("auth", response.data.token);
        handleToken(response.data.token); // Decode and handle token
        navigate("/admin-dashboard");
      }
  
      resetTextInput();
    } catch (error) {
      console.error("Error during login:", error);
      errorMessage();
    }
  };
  

  const handle2FASubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(VERIFY_2FA_URL, {
        userId,
        token,
      });

      if (response.data.success) {
        if (response.data.token) {
          localStorage.setItem("auth", response.data.token); // Store token
          handleToken(response.data.token); // Decode and handle token
          navigate("/admin-dashboard"); // Redirect to dashboard
        } else {
          setVerificationError(true);
        }
      } else {
        setVerificationError(true);
      }
    } catch (error) {
      setVerificationError(true);
    }
  };

  return (
    <>
      <BoxContainer>
        <section>
          <Box sx={{ p: 2 }}>
            <Typography align="center" paddingTop={3}>
              <img style={{ width: "125px" }} src={phlokkLogo} alt="logo" />
            </Typography>
            <Typography
              lineHeight={1.6}
              variant="h3"
              align="center"
              mb={2}
              pt={5}
              pb={5}
            >
              Secure Login
            </Typography>
  
            {!show2FA ? (
              // Standard login form
              <LoginBox className="loginBox">
                <form className="loginForm" onSubmit={handleSubmit}>
                  {error ? (
                    <Alert
                      sx={{ pb: 2, pt: 2 }}
                      severity="error"
                      icon={<ErrorOutlineIcon fontSize="inherit" />}
                    >
                      Your username or password is incorrect!
                    </Alert>
                  ) : null}
                  <StyledTextField
                    helperText="Please enter your email"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    required
                    type="email"
                  />
                  <StyledTextField
                    helperText="Please enter your password"
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    required
                    type="password"
                  />
                  <Button
                    disableRipple
                    variant="contained"
                    type="submit"
                    sx={{ mt: 2, p: 2 }}
                  >
                    Login
                  </Button>
                </form>
              </LoginBox>
            ) : (
              // 2FA flow: QR code (if needed) and token input
              <LoginBox className="loginBox">
                {qrCode ? (
                  <>
                    <Typography align="center" mb={2}>
                      Scan this QR code with your Google Authenticator app:
                    </Typography>
                    <Box align="center" mb={2}>
                      <img src={qrCode} alt="QR Code" />
                    </Box>
                  </>
                ) : (
                  <Typography align="center" mb={2}>
                    Enter your 6-digit 2FA code:
                  </Typography>
                )}
                <form onSubmit={handle2FASubmit}>
                  {verificationError ? (
                    <Alert severity="error">
                      Invalid 2FA token. Please try again.
                    </Alert>
                  ) : null}
                  <StyledTextField
                    helperText="Enter the 6-digit token"
                    label="2FA Token"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    margin="normal"
                    required
                  />
                  <Button
                    disableRipple
                    variant="contained"
                    type="submit"
                    sx={{ mt: 2, p: 2 }}
                  >
                    Verify
                  </Button>
                </form>
              </LoginBox>
            )}
          </Box>
        </section>
      </BoxContainer>
    </>
  );
  
};

export default Login;
