import React, { useEffect, useState } from "react";
import { Box, useTheme, Button, Modal, Typography } from "@mui/material";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import moment from "moment";
import { tokens } from "../../theme";
import axiosInstance from "../../api/axios";
import Header from "../../components/Header";
import { useSnackbar } from "notistack";
import IncidentReportForm from "../support/IncidentReportForum";
import { Avatar } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ADD_URL = "api/videos/videoSearch";
const DELETE_VIDEO_URL = "api/videos/deleteVideo/";
const GET_SOCIAL_SCORE_URL = "/api/scores/getSocialScore";
const UPDATE_SOCIAL_SCORE_URL = "/api/scores/updateSocialScore";

const VideoSearch = () => {
  const [videos, setVideos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [socialScore, setSocialScore] = useState(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axiosInstance.get(ADD_URL);
        console.log("Fetched videos:", response.data);
        setVideos(response.data);
      } catch (err) {
        console.error("Failed to fetch videos:", err);
      }
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    if (openModal && selectedVideo?._id) {
      fetchComments(selectedVideo._id);
    } else {
      // Clear out old comments if you want when the modal closes
      setComments([]);
    }
  }, [openModal, selectedVideo]);

  const fetchComments = async (postId) => {
    try {
      // Adjust to your actual endpoint path
      const response = await axiosInstance.get(
        `/api/videos/${postId}/videoComments`
      );
      setComments(response.data.comments);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleToggleComments = async () => {
    // If we’re about to *show* comments (and haven’t shown them yet),
    // fetch them for the selected post
    if (!showComments && selectedVideo) {
      await fetchComments(selectedVideo._id);
    }
    setShowComments((prev) => !prev);
  };

  const fetchSocialScore = async (userId) => {
    try {
      console.log("Fetching social score for userId:", userId);
      const response = await axiosInstance.get(
        `${GET_SOCIAL_SCORE_URL}/${userId}`
      );
      console.log("Social score response:", response.data);
      setSocialScore(response.data);
    } catch (err) {
      console.error("Error fetching social score:", err);
    }
  };

  const updateSocialScore = async (delta, reason, postId) => {
    try {
      await axiosInstance.patch(`${UPDATE_SOCIAL_SCORE_URL}/${postId}`, {
        scoreDelta: delta,
        reason: reason,
      });
      fetchSocialScore(postId); // Refresh the score after the update
    } catch (err) {
      console.error("Error updating social score:", err);
    }
  };

  useEffect(() => {
    if (openModal && selectedVideo?._id) {
      fetchSocialScore(selectedVideo._id); // Fetch social score when modal opens
    }
  }, [openModal, selectedVideo]);

  // Handle video delete
  const handleDelete = async (videoId) => {
    try {
      await axiosInstance.patch(`${DELETE_VIDEO_URL}${videoId}`);
      enqueueSnackbar("Video deleted successfully.", { variant: "success" });

      // Update the state to remove the deleted video from the list
      setVideos((prevVideos) =>
        prevVideos.filter((video) => video._id !== videoId)
      );
    } catch (err) {
      console.error("Failed to delete video:", err);
      enqueueSnackbar("Failed to delete video.", { variant: "error" });
    }
  };

  const handleOpenModal = (video) => {
    setSelectedVideo(video); // Set the entire video object
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedVideo(null);
  };

  const handleOpenFormModal = (video) => {
    setSelectedVideo(video);
    setIsFormModalOpen(true); // Open the report form modal
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false); // Close the report form modal
  };

  const handleOpenConfirmModal = (commentId) => {
    setCommentToDelete(commentId);
    setOpenConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setCommentToDelete(null);
  };

  // The function that actually calls the DELETE endpoint
  const handleDeleteComment = async (commentId) => {
    setIsDeleting(true);
    try {
      // Example route: /api/videos/soft/:commentId
      await axiosInstance.delete(`/api/videos/soft/${commentId}`);

      // Remove the deleted comment from state
      setComments((prevComments) =>
        prevComments.filter((c) => c._id !== commentId)
      );

      // Show success toast
      enqueueSnackbar("Comment deleted successfully!", { variant: "success" });
    } catch (error) {
      console.error("Failed to delete comment:", error);

      // Show error toast
      enqueueSnackbar("Failed to delete comment. Please try again.", {
        variant: "error",
      });
    } finally {
      setIsDeleting(false);
      handleCloseConfirmModal();
    }
  };

  // Renders comments (and nested replies) recursively
  const renderComments = (allComments, parentId = null, depth = 0) => {
    // Filter for the comments that belong to this parentId
    const childComments = allComments.filter(
      (comment) => comment.parent_comment_id === parentId
    );

    return childComments.map((comment) => (
      <Box
        key={comment._id}
        sx={{
          // Vertical spacing between comments
          mt: 2,

          // Indent nested replies
          ml: depth * 4,

          // Thicker left border for nested replies
          borderLeft: depth > 0 ? "2px solid #ccc" : "none",

          // Padding on the left if it's nested
          pl: depth > 0 ? 2 : 0,

          // Extra styling
          py: 2, // Vertical padding
          px: 3, // Horizontal padding
          borderRadius: 2,
          backgroundColor: "background.paper",
          boxShadow: 1,
          transition: "background-color 0.3s ease",

          // Subtle hover effect
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
      >
        {/* Avatar, username, verified badge */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Avatar
            src={comment.photo_url || ""}
            alt={comment.username ?? "Anonymous"}
            sx={{ width: 30, height: 30, mr: 1 }}
          />
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              mr: 0.5,
              display: "flex",
              alignItems: "center",
            }}
          >
            {comment.username ?? "Anonymous"}
            {comment.is_verified === 1 && (
              <CheckCircleIcon
                color="secondary"
                sx={{ fontSize: "0.8rem", ml: 0.5 }}
              />
            )}
          </Typography>
        </Box>

        {/* Comment text */}
        <Typography variant="body2" sx={{ mb: 1, whiteSpace: "pre-line" }}>
          {comment.message}
        </Typography>

        {/* Timestamp + Delete in same row */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Places timestamp on the left, Delete on the right
            mt: 1,
          }}
        >
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {moment(comment.created_at).format("YYYY-MM-DD HH:mm")}
          </Typography>
          <Button
            variant="contained"
            color="error"
            sx={{ fontSize: "0.7rem" }}
            onClick={() => handleOpenConfirmModal(comment._id)}
          >
            Delete
          </Button>
        </Box>

        {/* Nested replies */}
        {renderComments(allComments, comment._id, depth + 1)}
        <Modal
          open={openConfirmModal}
          onClose={handleCloseConfirmModal}
          aria-labelledby="confirm-delete-title"
          aria-describedby="confirm-delete-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <Typography
              id="confirm-delete-title"
              variant="h6"
              component="h2"
              sx={{ mb: 2 }}
            >
              Confirm Deletion
            </Typography>
            <Typography id="confirm-delete-description" sx={{ mb: 3 }}>
              Are you sure you want to delete this comment? This action cannot
              be undone.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}
            >
              <Button
                variant="outlined"
                onClick={handleCloseConfirmModal}
                sx={{
                  color: "text.primary",
                  borderColor: "text.primary",
                  "&:hover": {
                    backgroundColor: "error.light",
                    borderColor: "text.primary",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => handleDeleteComment(commentToDelete)}
                disabled={isDeleting}
                sx={{
                  backgroundColor: "black",
                  color: "error.main",
                  border: "2px solid",
                  borderColor: "error.main",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "error.main",
                    color: "black",
                    border: "2px solid black",
                  },
                }}
              >
                {isDeleting ? "Deleting..." : "Delete Comment"}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    ));
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "photo_url",
      headerName: "Profile Image",
      width: 100,
      renderCell: (params) =>
        params.row.photo_url ? (
          <img
            src={params.row.photo_url}
            alt={`${params.row.username}'s profile`}
            style={{ width: 40, height: 40, borderRadius: "50%" }}
          />
        ) : (
          "No photo"
        ),
    },
    {
      field: "username",
      headerName: "Username",
      width: 150,
    },
    {
      field: "is_verified",
      headerName: "Verified",
      width: 150,
    },
    {
      field: "is_founder",
      headerName: "Founder",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
    },
    {
      field: "videoUrl",
      headerName: "Video Link",
      width: 150,
      renderCell: (params) =>
        params.row.videoUrl ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary", // Use the theme warning color
              color: "white",
              "&:hover": {
                backgroundColor: "secondary.main", // Use a darker variant for hover
              },
            }}
            onClick={() => handleOpenModal(params.row)}
          >
            View Video
          </Button>
        ) : (
          "No video available"
        ),
    },
    {
      field: "_id",
      headerName: "PostId",
      width: 300,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
    },
    {
      field: "play_count",
      headerName: "Play Count",
      width: 80,
    },
    {
      field: "privacy_type",
      headerName: "Privacy Type",
      width: 80,
    },
    {
      field: "is_duo",
      headerName: "DUO",
      width: 80,
    },
    {
      field: "created_at",
      type: "date",
      headerName: "Created On",
      valueGetter: (params) => new Date(params.row.created_at),
      renderCell: (params) =>
        moment(params.row.created_at).format("YYYY-MM-DD"),
      width: 150,
    },
    {
      field: "updated_at",
      type: "date",
      headerName: "Updated Date",
      valueGetter: (params) => new Date(params.row.updated_at),
      renderCell: (params) =>
        moment(params.row.updated_at).format("YYYY-MM-DD"),
      width: 150,
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          sx={{
            backgroundColor: "warning.main", // Use the theme warning color
            color: "white",
            "&:hover": {
              backgroundColor: "warning.dark", // Use a darker variant for hover
            },
          }}
          onClick={() => handleDelete(params.row._id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  const rows = videos.map((row) => ({
    _id: row._id,
    username: row.username,
    photo_url: row.photo_url,
    type: row.type,
    is_duo: row.is_duo,
    is_verified: row.is_verified,
    is_founder: row.is_founder,
    description: row.description,
    videoUrl: row.videoUrl,
    play_count: row.play_count,
    privacy_type: row.privacy_type,
    created_at: row.created_at,
    updated_at: row.updated_at,
  }));

  return (
    <Box m="20px">
      <Header title="Search Videos" subtitle="Video Database" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={rows}
          getRowId={(row) => row._id}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light"
                  ? colors.primary[100]
                  : colors.primary[700],
            },
          }}
        />
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-video-title"
        aria-describedby="modal-video-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%", // Wider modal
            maxHeight: "90vh", // Limit modal height
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "row", // Align content horizontally
            overflowY: "auto",
            gap: 4, // Space between sections
          }}
        >
          {/* Video Section */}
          <Box
            sx={{
              flex: "1", // Video takes 1/3 of the width
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pr: 2, // Padding to separate video and data
            }}
          >
            {selectedVideo && (
              <video
                width="100%"
                controls
                autoPlay
                src={selectedVideo.videoUrl}
              >
                Your browser does not support the video tag.
              </video>
            )}
          </Box>

          {/* Data Section (now includes the comments box inside) */}
          <Box
            sx={{
              flex: "2", // Data takes 2/3 of the width
              display: "flex",
              flexDirection: "column",
              gap: 2, // Space between rows of data
              overflowY: "auto",
            }}
          >
            {selectedVideo && (
              <>
                {/* Basic User and Video Info */}
                <Typography variant="h6" id="modal-video-title">
                  <strong style={{ color: theme.palette.secondary.main }}>
                    Username:
                  </strong>{" "}
                  {selectedVideo.username}
                </Typography>
                <Typography variant="body1">
                  <strong style={{ color: theme.palette.secondary.main }}>
                    Post ID:
                  </strong>{" "}
                  {selectedVideo._id}
                </Typography>
                <Typography variant="body1">
                  <strong style={{ color: theme.palette.secondary.main }}>
                    Description:
                  </strong>{" "}
                  {selectedVideo.description}
                </Typography>

                {/* Data Grid for Metrics */}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)", // Two columns
                    gap: 2,
                  }}
                >
                  <Typography variant="body2">
                    <strong style={{ color: theme.palette.secondary.main }}>
                      Uploaded On:
                    </strong>{" "}
                    {moment(selectedVideo.created_at).format("YYYY-MM-DD")}
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: theme.palette.secondary.main }}>
                      Privacy Type:
                    </strong>{" "}
                    {selectedVideo.privacy_type}
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: theme.palette.secondary.main }}>
                      Type:
                    </strong>{" "}
                    {selectedVideo.type}
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: theme.palette.secondary.main }}>
                      Play Count:
                    </strong>{" "}
                    {selectedVideo.play_count}
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: theme.palette.secondary.main }}>
                      Violations:
                    </strong>{" "}
                    No violations
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: theme.palette.secondary.main }}>
                      Reports:
                    </strong>{" "}
                    0
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: theme.palette.secondary.main }}>
                      DUO:
                    </strong>{" "}
                    {selectedVideo.is_duo ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: theme.palette.secondary.main }}>
                      Social Score:
                    </strong>{" "}
                    {socialScore ? socialScore.score : "Loading..."}
                  </Typography>
                </Box>

                {/* Score Update Buttons */}
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      updateSocialScore(
                        50,
                        "Positive Contribution",
                        selectedVideo._id
                      )
                    }
                    disabled={socialScore && socialScore.score >= 750}
                  >
                    +50
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      updateSocialScore(
                        100,
                        "Outstanding Contribution",
                        selectedVideo._id
                      )
                    }
                    disabled={socialScore && socialScore.score >= 750}
                  >
                    +100
                  </Button>

                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() =>
                      updateSocialScore(
                        -50,
                        "Minor Violation",
                        selectedVideo._id
                      )
                    }
                  >
                    -50
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      updateSocialScore(
                        -100,
                        "Major Violation",
                        selectedVideo._id
                      )
                    }
                  >
                    -100
                  </Button>
                </Box>
              </>
            )}

            {/* Comments Box (moved into the Data Section so it won't appear below absolute buttons) */}
            {showComments && (
              <Box
                sx={{
                  marginTop: 2,
                  padding: 2,
                  border: "1px solid #ccc",
                  borderRadius: 2,
                  width: "50%", // or whatever width you want
                  height: "600px", // fixed height
                  overflowY: "auto",
                  flex: "0 0 auto",
                }}
              >
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Comments
                </Typography>
                {comments.length === 0 ? (
                  <Typography variant="body2">No comments yet.</Typography>
                ) : (
                  renderComments(comments, null, 0)
                )}
              </Box>
            )}
          </Box>

          {/* Close Button at Bottom Right (unchanged) */}
          <Box
            sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
              display: "flex", // Ensure buttons are displayed in a row
              gap: 2, // Adds space between the buttons
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button variant="contained" onClick={handleToggleComments}>
              {showComments ? "Hide Comments" : "Show Comments"}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleOpenFormModal(selectedVideo)} // Opens the report form modal
            >
              Fill Out Report
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={isFormModalOpen}
        onClose={handleCloseFormModal}
        aria-labelledby="report-form-modal"
        aria-describedby="incident-report-form"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%", // Full-size modal
            height: "80%", // Ensures the modal takes up most of the viewport height
            minHeight: "400px", // Fallback minimum height
            maxHeight: "90vh", // Limits modal height to 90% of viewport height
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflowY: "auto", // Enables scrolling for overflow content
          }}
        >
          <IncidentReportForm
            selectedVideo={selectedVideo}
            onClose={handleCloseFormModal}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default VideoSearch;
