// import jwt_decode from "jwt-decode";
import jwtDecode from "jwt-decode";


// export const userRole = () => {
//   return jwt_decode(localStorage.getItem('auth')).role;
// }

// export const userName = () => {
//   return jwt_decode(localStorage.getItem('auth')).name;
// }

export const userRole = () => {
  const token = localStorage.getItem("auth");
  if (!token) return null;
  return jwtDecode(token).role;
};

export const userName = () => {
  const token = localStorage.getItem("auth");
  if (!token) return null;
  return jwtDecode(token).username;
};