import { useState, useEffect, useCallback, forwardRef } from "react";
import {
  Modal,
  Box,
  Typography,
  useTheme,
  Snackbar,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  gridClasses,
} from "@mui/x-data-grid";
import MuiAlert from "@mui/material/Alert";
import { tokens } from "../../theme";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import Header from "../../components/Header";
import axiosInstance from "../../api/axios";
import moment from "moment";
import { userRole } from "../../services/jwt";

const INCIDENT_URL = "api/support/supervisorIncidentData";
const CLOSE_INCIDENT_URL = "api/support/closeTicket";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IncidentReports = () => {
  const [open, setOpen] = useState(false);
  const role = userRole();
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const getAllIncidentReports = async () => {
    await axiosInstance.get(INCIDENT_URL).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getAllIncidentReports();
  }, []);


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 80,
      type: "singleSelect",
      valueOptions: ["open", "closed"],
      editable: true,
      cellClassName: "name-column--cell",
    },
    {
      field: "incident_date",
      headerName: "Date of Incident",
      width: 150,
      renderCell: (params) =>
        moment(params.row.created_at).format("YYYY-MM-DD HH:MM"),
    },
    {
      field: "admin_username",
      headerName: "Report from",
      width: 150,
    },

    {
      field: "offender_username",
      headerName: "Offender",
      width: 150,
      renderCell: ({ row }) => {
        if (!row.offender_username) {
          return "N/A";
        }
        return row.offender_username;
      },
    },
    {
      field: "infraction_charge",
      headerName: "Infraction Type",
      width: 150,
      cellClassName: "violation-column--cell",
    },
    {
      field: "incident_description",
      headerName: "View Description",
      width: 150,
      renderCell: (params) => (
        <ChatOutlinedIcon
          sx={{ color: "#00CEC9", cursor: "pointer" }}
          onClick={() => {
            setSelectedRow(params.row);
            setSelectedDescription(params.row.incident_description);
            setModalOpen(true);
          }}
        />
      ),
    },
    {
      field: "assign_to",
      headerName: "Assigned To",
      width: 100,
    },
    {
      field: "created_at",
      headerName: "Created at",
      width: 150,
      renderCell: (params) =>
        moment(params.row.created_at).format("YYYY-MM-DD HH:MM"),
    },
    {
      field: "updated_at",
      headerName: "Updated at",
      width: 150,
      renderCell: (params) => {
        const updatedAt = params.row.updated_at;
        return updatedAt ? moment(updatedAt).format("YYYY-MM-DD HH:mm") : "N/A"; // Fallback value
      },
    },

    {
      field: "post_id",
      headerName: "Post ID",
      width: 300,
      renderCell: ({ row }) => {
        if (!row.post_id) {
          return "N/A";
        }
        return row.post_id;
      },
    },

    {
      field: "_id",
      headerName: "ID",
      width: 300,
    },
  ];

  const handleUpdated = useCallback(async (newRow) => {
    await axiosInstance
      .get(CLOSE_INCIDENT_URL + "/" + newRow._id)
      .then(async function () {
        setOpen(true);
      })
      .catch(function (e) {
        console.log("Error deleting data", e);
      });
    const updateRow = { ...newRow };
    setData((prevData) => {
      const updatedData = prevData.filter((item) => {
        if (item._id !== newRow._id) {
          return { ...item };
        }
      });

      return updatedData;
    });
    return updateRow;
  }, []);

  const rows = data.map((row) => ({
    _id: row._id,
    admin_username: row.admin_username,
    incident_description: row.incident_description,
    incident_date: row.incident_date,
    infraction_charge: row.infraction_charge,
    status: row.status,
    created_at: row.created_at,
    updated_at: row.updated_at,
    closed_at: row.closed_at,
    assign_to: row.assign_to,
    location: row.location,
    names_and_roles: row.names_and_roles,
    actions: row.actions,
    incident_trigger: row.incident_trigger,
    contributing_factors: row.contributing_factors,
    escalation: row.escalation,
    offender_username: row.offender_username,
    post_id: row.post_id,
  }));

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 6,
    borderRadius: 4,
    overflow: "auto",
    maxHeight: "90vh",
  };

  function CustomGridToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {role === "admin" ? <GridToolbarExport /> : null}
      </GridToolbarContainer>
    );
  }

  return (
    <Box m="20px">
      <Header title="Support Center" subtitle="Incident Reports" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .violation-column--cell": {
            color: "#ffeaa7",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={rows}
          getRowId={(row) => row._id}
          columns={columns}
          slots={{ toolbar: CustomGridToolbar }}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          onProcessRowUpdateError={() => {}}
          processRowUpdate={handleUpdated}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light"
                  ? colors.primary[100]
                  : colors.primary[700],
            },
          }}
        />
      </Box>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Incident report has been successfully closed!
        </Alert>
      </Snackbar>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            position: "relative",
            paddingBottom: "120px",
            maxHeight: "80vh", // Set the max height for the form
            overflowY: "auto",
          }}
        >
          <Typography
            id="modal-title"
            variant="h5"
            component="h2"
            color="error"
            sx={{ paddingBottom: 4 }}
          >
            <strong>Incident Report</strong>
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 3 }}
          >
            <Typography>
              <strong>Date of Incident:</strong>
            </Typography>
            {selectedRow?.created_at
              ? moment(selectedRow.created_at).format(
                  "MMMM Do, YYYY"
                )
              : "N/A"}
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 3 }}
          >
            <Typography>
              <strong>Report by:</strong>
            </Typography>
            {selectedRow?.admin_username}
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 3 }}
          >
            <Typography>
              <strong>Offender:</strong>
            </Typography>
            {selectedRow?.offender_username}
          </Typography>

          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 3 }}
          >
            <Typography>
              <strong>Infraction Charge:</strong>
            </Typography>
            {selectedRow?.infraction_charge}
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 3 }}
          >
            <Typography>
              <strong>Location:</strong>
            </Typography>
            {selectedRow?.location}
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 3 }}
          >
            <Typography>
              <strong>Names and Roles:</strong>
            </Typography>
            {selectedRow?.names_and_roles}
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 3 }}
          >
            <Typography>
              <strong>Contributing Factors:</strong>
            </Typography>
            {selectedRow?.contributing_factors}
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 3 }}
          >
            <Typography>
              <strong>Incident Trigger:</strong>
            </Typography>
            {selectedRow?.incident_trigger}
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 3 }}
          >
            <Typography>
              <strong>Actions:</strong>
            </Typography>
            {selectedRow?.actions}
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 3 }}
          >
            <Typography>
              <strong>Incident Description:</strong>
            </Typography>
            {selectedDescription || "No description available."}
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: 1 }}
          >
            <Typography>
              <strong>Escalation:</strong>
            </Typography>
            {selectedRow?.escalation || "N/A"}
          </Typography>

          <Typography id="modal-description" sx={{ mt: 2 }}></Typography>
          <Box
            sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
            }}
          >
            <Button
              onClick={() => setModalOpen(false)}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default IncidentReports;
