import { useState, useEffect } from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/MockData";
import {
  Support,
  DownloadOutlined,
  Flag,
  ShowChart,
  ManageAccounts,
  Videocam,
  Block,
  CheckCircle,
  PersonAdd,
} from "@mui/icons-material";
import Header from "../../components/Header";
import GeographyChart from "../../components/GeographyChart";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import { motion } from "framer-motion";

import ProgressCircle from "../../components/ProgressCircle";
import { userRole } from "../../services/jwt";
import EmployeeDashboard from "./EmployeeDashboard";
import axiosInstance from "../../api/axios";
import StreamChart from "../../components/StreamChart";
import CalenderChart from "../../components/CalenderChart";
import { userName } from "../../services/jwt";

const REPORTS_URL = "api/support/videoReportData";
const USERS_URL = "api/app/appUsersData";
const FOUNDERS_URL = "api/founders/foundersData";
const BANNED_USERS_URL = "api/app/appUsersBannedData";

const Dashboard = () => {
  // const role = userRole();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // const username = userName();
  const [userData, setUserData] = useState([]);
  const [foundersCount, setFoundersCount] = useState([]);
  const [userBannedCount, setUserBannedCount] = useState([]);
  const [totalReportsCount, setTotalReportsCount] = useState([]);
  const [videoReportsCount, setVideoReportsCount] = useState([]);
  const [creatorReportsCount, setCreatorReportsCount] = useState([]);
  const [supportReportsCount, setSupportReportsCount] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [role, setRole] = useState(null);
  const [username, setUsername] = useState("");

  const MotionBox = motion(Box);

  const getAllData = async () => {
    try {
      setIsDataLoaded(false);
      // ---- REPORTS ----
      const reportsRes = await axiosInstance.get(REPORTS_URL);
      const reportsData = reportsRes.data || [];
      setTotalReportsCount(
        reportsData.filter((item) => item._id !== null)?.length || 0
      );
      setVideoReportsCount(
        reportsData.filter((item) => item.type === "video")?.length || 0
      );
      setCreatorReportsCount(
        reportsData.filter((item) => item.type === "creator")?.length || 0
      );
      setSupportReportsCount(
        reportsData.filter((item) => item.type === "support")?.length || 0
      );

      // ---- USERS ----
      const usersRes = await axiosInstance.get(USERS_URL);
      const usersData = usersRes.data || [];
      setUserData(
        usersData.filter((item) => item.banned_at === null)?.length || 0
      );

      // ---- FOUNDERS ----
      const foundersRes = await axiosInstance.get(FOUNDERS_URL);
      const foundersData = foundersRes.data || [];
      setFoundersCount(
        foundersData.filter((item) => item.is_blocked === 0)?.length || 0
      );

      // ---- BANNED USERS ----
      const bannedUsersRes = await axiosInstance.get(BANNED_USERS_URL);
      const bannedUsersData = bannedUsersRes.data || [];
      setUserBannedCount(
        bannedUsersData.filter((item) => item.banned_at !== null)?.length || 0
      );
      setIsDataLoaded(true);
    } catch (error) {
      console.error("Error fetching data =>", error);
    }
  };

   // Fetch role and username from token on mount
   useEffect(() => {
    const tokenRole = userRole(); // Get role from token
    const tokenUsername = userName(); // Get username from token

    if (tokenRole) setRole(tokenRole);
    if (tokenUsername) setUsername(tokenUsername);

    if (tokenRole) {
      getAllData(); // Fetch data if role is available
    }
  }, []);

  const handleDownloadReports = () => {
    // 1) Gather the data you want in your report
    const dataForExport = [
      {
        totalReportsCount,
        foundersCount,
        userData,
        creatorReportsCount,
        videoReportsCount,
        supportReportsCount,
        userBannedCount,
        sales: (foundersCount * 25.0).toFixed(2),

        
      },
    ];

    // 2) Convert that array of objects into CSV
    //    Let's define our column headers as the keys:
    const headers = Object.keys(dataForExport[0]); // e.g., ["totalReportsCount", "foundersCount", ...]

    // Build the CSV rows
    const csvRows = dataForExport.map((row) =>
      headers.map((fieldName) => row[fieldName]).join(",")
    );

    // Combine headers and rows
    const csvString = [headers.join(","), ...csvRows].join("\n");

    // 3) Create a Blob from your CSV, then create a hidden download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "my_dashboard_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // 2. Call getAllData only when we know the user's role (or any other required info).
  useEffect(() => {
    if (role) {
      // If role is "admin", for example, then fetch the data
      getAllData();
    }
  }, [role]);

  return (
    <Box m="20px">
      {role === "admin" ? (
        !isDataLoaded ? (
          <Box
            m="20px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="75vh" // or any desired height
          >
            <Typography variant="h5">Loading dashboard data...</Typography>
          </Box>
        ) : (
          <>
            {/* HEADER */}
            <MotionBox
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              layout={false}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Header title="Admin" subtitle={`Welcome back, ${username}`} />

                <Box>
                  <Button
                    onClick={handleDownloadReports}
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                  >
                    <DownloadOutlined sx={{ mr: "10px" }} />
                    Download Reports
                  </Button>
                </Box>
              </Box>

              {/* GRID & CHARTS */}
              <Box
                sx={{
                  display: { xs: "flex", md: "grid" },
                  gridTemplateColumns: "repeat(12, 1fr)",
                  gridAutoRows: "minmax(150px, auto)",
                  flexDirection: "column",
                }}
                gap="20px"
              >
                {/* ROW 1 */}
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                  paddingBottom="10px"
                >
                  <StatBox
                    title={totalReportsCount}
                    subtitle="Total Reports"
                    progress="0.00"
                    increase="0%"
                    icon={
                      <Flag
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                  paddingBottom="10px"
                >
                  <StatBox
                    title={`$${(foundersCount * 25.0).toFixed(2)}`}
                    subtitle="Sales"
                    progress="0.00"
                    increase="0%"
                    icon={
                      <ShowChart
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                  paddingBottom="10px"
                >
                  <StatBox
                    title={userData}
                    subtitle="Application Users"
                    progress="0.00"
                    increase="0%"
                    icon={
                      <PersonAdd
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                  paddingBottom="10px"
                >
                  <StatBox
                    title={foundersCount}
                    subtitle="Founders"
                    progress="0.00"
                    increase="0%"
                    icon={
                      <CheckCircle
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>

                {/* ROW 2 */}
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                  paddingBottom="10px"
                >
                  <StatBox
                    title={creatorReportsCount}
                    subtitle="Creator Reports"
                    progress="0.00"
                    increase="0%"
                    icon={
                      <ManageAccounts
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                  paddingBottom="10px"
                >
                  <StatBox
                    title={videoReportsCount}
                    subtitle="Video Reports"
                    progress="0.00"
                    increase="0%"
                    icon={
                      <Videocam
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                  paddingBottom="10px"
                >
                  <StatBox
                    title={supportReportsCount}
                    subtitle="Support Reports"
                    progress="0.00"
                    increase="0%"
                    icon={
                      <Support
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                  paddingBottom="10px"
                >
                  <StatBox
                    title={userBannedCount}
                    subtitle="Banned Users"
                    progress="0.00"
                    increase="0%"
                    icon={
                      <Block
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>

                {/* ROW 3 */}
                <Box
                  gridColumn="span 8"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                >
                  <Box
                    mt="25px"
                    p="0 30px"
                    display="flex "
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                        Revenue Generated
                      </Typography>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        color={colors.greenAccent[500]}
                      >
                        ${foundersCount * 25.0}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton>
                        <DownloadOutlined
                          sx={{
                            fontSize: "26px",
                            color: colors.greenAccent[500],
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box height="250px" m="-20px 0 0 0">
                    <LineChart isDashboard={true} />
                  </Box>
                </Box>
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  overflow="auto"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    colors={colors.grey[100]}
                    p="15px"
                  >
                    <Typography
                      color={colors.grey[100]}
                      variant="h5"
                      fontWeight="600"
                    >
                      Recent Transactions
                    </Typography>
                  </Box>
                  {mockTransactions.map((transaction, i) => (
                    <Box
                      key={`${transaction.txId}-${i}`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      borderBottom={`4px solid ${colors.primary[500]}`}
                      p="15px"
                    >
                      <Box>
                        <Typography
                          color={colors.greenAccent[500]}
                          variant="h5"
                          fontWeight="600"
                        >
                          {transaction.txId}
                        </Typography>
                        <Typography color={colors.grey[100]}>
                          {transaction.user}
                        </Typography>
                      </Box>
                      <Box color={colors.grey[100]}>{transaction.date}</Box>
                      <Box
                        sx={{
                          backgroundColor: "#0c101b",
                          color: colors.greenAccent[500],
                          border: 1,
                          borderColor: "#00CEC9",
                        }}
                        p="5px 10px"
                        borderRadius="4px"
                      >
                        ${transaction.cost}
                      </Box>
                    </Box>
                  ))}
                </Box>

                {/* ROW 4 */}
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  p="30px"
                >
                  <Typography variant="h5" fontWeight="600">
                    Campaign
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt="25px"
                  >
                    <ProgressCircle size="125" />
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      sx={{ mt: "15px" }}
                    >
                      ${foundersCount * 25.0} Gross Sales
                    </Typography>
                    <Typography>Includes icons</Typography>
                  </Box>
                </Box>
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                >
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    sx={{ padding: "30px 30px 0 30px" }}
                  >
                    Sales
                  </Typography>
                  <Box height="250px" mt="-20px">
                    <BarChart isDashboard={true} />
                  </Box>
                </Box>
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  padding="30px"
                >
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    sx={{ marginBottom: "15px" }}
                  >
                    Geography Based Traffic
                  </Typography>
                  <Box height="200px">
                    <GeographyChart isDashboard={true} />
                  </Box>
                </Box>
                {/* ROW 3 */}
                <Box
                  gridColumn="span 12"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                >
                  <Box
                    mt="25px"
                    p="0 30px"
                    display="flex "
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                        In App Purchases
                      </Typography>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        color={colors.greenAccent[500]}
                      >
                        $0
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton>
                        <DownloadOutlined
                          sx={{
                            fontSize: "26px",
                            color: colors.greenAccent[500],
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    height="500px"
                    paddingBottom="40px"
                    paddingTop="40px"
                    m="-20px 0 0 0"
                  >
                    <StreamChart isDashboard={true} />
                  </Box>
                </Box>
                {/* ROW 4 */}
                <Box
                  gridColumn="span 12"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                >
                  <Box
                    mt="25px"
                    p="0 30px"
                    display="flex "
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                        Monthly Reports
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton>
                        <DownloadOutlined
                          sx={{
                            fontSize: "26px",
                            color: colors.greenAccent[500],
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    height="500px"
                    paddingBottom="40px"
                    paddingTop="40px"
                    m="-20px 0 0 0"
                  >
                    <CalenderChart isDashboard={true} />
                  </Box>
                </Box>
              </Box>
            </MotionBox>
          </>
        )
      ) : (
        <EmployeeDashboard />
      )}
    </Box>
  );
};

export default Dashboard;
