import { useState, useEffect } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import EmployeeLineChart from "../../components/EmployeeLineChart";
import axiosInstance from "../../api/axios";
import {HighlightOff, DownloadOutlined, Flag, Announcement, ManageAccounts, Videocam, Support} from '@mui/icons-material';
import { userName } from "../../services/jwt";
import { motion } from "framer-motion";

const REPORTS_URL = "api/support/videoReportData";
const CLOSED_REPORTS_URL = "api/support/closedReportsData";

const MotionBox = motion(Box);

const EmployeeDashboard = () => {
  const username = userName();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openReportsCount, setOpenReportsCount] = useState([]);
  const [totalReportsCount, setTotalReportsCount] = useState([]);
  const [closedReportsCount, setClosedReportsCount] = useState([]);
  const [videoReportsCount, setVideoReportsCount] = useState([]);
  const [creatorReportsCount, setCreatorReportsCount] = useState([]);
  const [supportReportsCount, setSupportReportsCount] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        // Fetch both endpoints in parallel
        const [reportsRes, closedRes] = await Promise.all([
          axiosInstance.get(REPORTS_URL),
          axiosInstance.get(CLOSED_REPORTS_URL),
        ]);

        // Handle reportsRes
        const resData = reportsRes.data || [];
        const reportsCount = resData.filter((item) => item._id !== null).length || 0;
        const openCount = resData.filter(
          (item) => item.status === "open" && item.assign_to !== "security"
        ).length || 0;
        const videoReportCount = resData.filter((item) => item.type === "video").length || 0;
        const creatorReportCount = resData.filter((item) => item.type === "creator").length || 0;
        const supportReportCount = resData.filter((item) => item.type === "support").length || 0;

        setOpenReportsCount(openCount);
        setTotalReportsCount(reportsCount);
        setVideoReportsCount(videoReportCount);
        setCreatorReportsCount(creatorReportCount);
        setSupportReportsCount(supportReportCount);

        // Handle closedRes
        const closedData = closedRes.data || [];
        const getAllClosedReports = closedData.filter((item) => item._id !== null).length || 0;
        setClosedReportsCount(getAllClosedReports);

        // Done fetching => set loaded
        setIsDataLoaded(true);
      } catch (err) {
        console.log("error fetching data =>", err);
      }
    };

    fetchAllData();
  }, []);

   // If not loaded yet, show a loading message
   if (!isDataLoaded) {
    return (
      <Box m="20px">
        <Box
  m="20px"
  display="flex"
  justifyContent="center"
  alignItems="center"
  height="75vh" // or any desired height
>
  <Typography variant="h5">Loading Customer Support...</Typography>
</Box>
      </Box>
    );
  }

  return (
    <MotionBox
      m="20px"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      layout={false}
    >
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Customer Support" subtitle={`Welcome back, ${username}`} />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        sx={{
          display: { xs: "flex", md: "grid" },
          gridTemplateColumns: "repeat(12, 1fr)",
          gridAutoRows: "minmax(150px, auto)",
          flexDirection: "column",
        }}
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={totalReportsCount}
            subtitle="Total Reports"
            progress="0.75"
            increase="+14%"
            icon={
              <Flag
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={openReportsCount}
            subtitle="Open Reports"
            progress="0.00"
            increase="0%"
            icon={
              <Announcement
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={closedReportsCount}
            subtitle="Closed Reports"
            progress="0.30"
            increase="+5%"
            icon={
              <HighlightOff
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        

        {/* GRID & CHARTS */}

        {/* ROW 2 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={creatorReportsCount}
            subtitle="Creator Reports"
            progress="0.00"
            increase="0%"
            icon={
              <ManageAccounts
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={videoReportsCount}
            subtitle="Video Reports"
            progress="0.75"
            increase="+14%"
            icon={
              <Videocam
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <StatBox
            title={supportReportsCount}
            subtitle="Support Reports"
            progress="0.30"
            increase="+5%"
            icon={
              <Support
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        

        {/* ROW 3 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Reports Handled
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {totalReportsCount}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlined
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <EmployeeLineChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </MotionBox>
  );
};

export default EmployeeDashboard;
