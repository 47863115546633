import { useState } from "react";
import { BoxContainer, RegisterBox, StyledTextField } from "../../styles/Container.Styled";
import { Button, Typography, Box, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Alert from "@mui/material/Alert";

import axiosInstance from "../../api/axios";

const REGISTER_URL = "api/auth/register";

const Register = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const successMessage = () => {
    setSuccess(true);
  }

  function resetTextInput() {
    setName("");
    setUsername("");
    setEmail("");
    setPassword("");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post(REGISTER_URL, {
        name: name,
        username: username,
        email: email,
        password: password,
      })
      .then(async function (response) {
        const registerEmployee = response.data.user[0];
        registerEmployee.token = response.data.token;

        resetTextInput();
        successMessage();
      })
      .catch(function (e) {
        console.log("Error registering", e);
      });
  };

  // const colors = tokens(theme.palette.mode);
  
  return (
    <>
      <BoxContainer>
        <section>
          <Box sx={{ p: 2 }}>
            <Typography align="center" paddingTop={3}>
            </Typography>
            <Typography
              lineHeight={1.6}
              variant="h3"
              align="center"
              mb={2}
              pb={5}
            >
              Secure Register
            </Typography>
            <RegisterBox className="loginBox">
            <form className="loginForm" onSubmit={handleSubmit}>
            
          { success ? <Alert sx={{pt: 2, pb: 2,}} icon={<CheckCircleOutlineIcon fontSize="inherit" />} severity="success">
        Data entry successful! 
      </Alert> : null}

            <StyledTextField
                helperText="Enter employee name"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                margin="normal"
                required
              />
              <StyledTextField
                helperText="Enter employee username"
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
                required
              />
              <StyledTextField
                helperText="Enter employee email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                required
                type="email"
              />
              <StyledTextField
                helperText="Enter employee password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                required
                type="password"
              />
              <Button
              disableRipple
              variant="contained"
              type="submit"
              sx={{ mt: 2, p: 2 }}
            >
              Register
            </Button>
            </form>
            </RegisterBox>
          </Box>
        </section>
      </BoxContainer>
    </>
  )
}

export default Register;