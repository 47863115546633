import { Routes, Route } from "react-router-dom";
import Dashboard from "./scenes/dashboard/Dashboard";
import Founders from "./scenes/founders/Founders";
import Line from "./scenes/line/Line";
import Bar from "./scenes/bar/Bar";
import Pie from "./scenes/pie/Pie";
import Geography from "./scenes/geography/Geography";
import Login from "./scenes/login/Login";
import MainLayout from "./layout/MainLayout";
import LoginLayout from "./layout/LoginLayout";
import NotFound from "./components/NotFound";
import Register from "./scenes/employees/Register";
import Employees from "./scenes/employees/Employees";
import Sounds from "./scenes/sounds/Sounds";
import Users from "./scenes/users/Users";
import NewsTicker from "./scenes/ticker/NewsTicker";
import AddSounds from "./scenes/sounds/AddSounds";
import Carousel from "./scenes/carousel/Carousel";
import Updates from "./scenes/ticker/Updates";
import KnowledgeBase from "./scenes/knowledge/KnowledgeBase";
import Transactions from "./scenes/transactions/Transactions";
import EmployeeDashboard from "./scenes/dashboard/EmployeeDashboard";
import SecurityDashboard from "./scenes/dashboard/SecurityDashboard";
import Reports from "./scenes/support/Reports";
import RespondReport from "./scenes/support/RespondReport";
import NotifyUser from "./scenes/users/NotifyUser";
import ArtistScreen from "./scenes/sounds/ArtistScreen";
import UsersProfile from "./scenes/users/UsersProfile";
import SecurityReports from "./scenes/support/SecurityReports";
import KnowledgeUsersBase from "./scenes/knowledge/KnowledgeUsersBase";
import SplashUpdates from "./scenes/splash/SplashUpdates";
import SplashScreenUI from "./scenes/splash/SplashScreenUI";
import VideoSearch from "./scenes/videos/VideoSearch";
import IncidentReports from "./scenes/support/IncidentReports";
import BiditSearch from "./scenes/bidit/BiditSearch";

function App() {
  
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/admin-dashboard" element={<Dashboard />} />
        <Route path="/support-dashboard" element={<EmployeeDashboard />} />
        <Route path="/security-dashboard" element={<SecurityDashboard />} />
        <Route path="/sounds" element={<Sounds />} />
        <Route path="/biditSearch" element={<BiditSearch />} />
        <Route path="/artist/:id" element={<ArtistScreen />} />
        <Route path="/addSounds" element={<AddSounds />} />
        <Route path="/carousel" element={<Carousel />} />
        <Route path="/ticker" element={<NewsTicker />} />
        <Route path="/splash" element={<SplashUpdates />} />
        <Route path="/splashData" element={<SplashScreenUI />} />
        <Route path="/searchVideos" element={<VideoSearch />} />
        <Route path="/updates" element={<Updates />} />
        <Route path="/knowledge" element={<KnowledgeBase />} />
        <Route path="/knowledge-violator" element={<KnowledgeUsersBase />} />
        <Route path="/verified" element={<Founders />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users-profile/:id" element={<UsersProfile />} />
        <Route path="/notify-user/:id" element={<NotifyUser />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/security-reports" element={<SecurityReports />} />
        <Route path="/incident-reports" element={<IncidentReports />} />
        <Route path="/respond-report/:id" element={<RespondReport />} />
        <Route path="/transactions" element={<Transactions />} />
        {/* <Route path="/notifications" element={<Notifications />} /> */}
        <Route path="/employees" element={<Employees />} />
        <Route path="/register" element={<Register />} />
        <Route path="/bar" element={<Bar />} />
        <Route path="/pie" element={<Pie />} />
        <Route path="/line" element={<Line />} />
        <Route path="/geography" element={<Geography />} />
        <Route />
      </Route>
    </Routes>
  );
}

export default App;
