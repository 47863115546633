// InfractionChargeSelect.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const InfractionChargeSelect = ({ value, onChange, inputStyles }) => {
  const infractionCharges = [
"Adult Nudity and Sex Acts (Automatic Ban)",
"Child Abuse (Automatic Ban)",
"Controlled Substance Abuse (-50 pts)",
"Dangerous Individuals or Dangerous Content (-150 pts)",
"Deceptive Techniques (Automatic Ban)",
"Doxing, Hacking, Extortion (Automatic Ban)",
"Firearms or Weapons (-100 pts, (AB after 3 strikes))",
"Frauds and Scams (Automatic Ban)",
"Graphic Content (-20 pts)",
"Harassment (Automatic Ban)",
"Hatful Ideology (Automatic Ban)",
"Hate Speech and Bullying (Automatic Ban)",
"Illegal Activities (Automatic Ban)",
"Malicious Propaganda (-20 pts)",
"Malicious Reporting (Automatic Ban)",
"Minor Safety, Grooming A Minor (Automatic Ban)",
"Pornographic Content (-75 pts)",
"Sexual Exploitation (Automatic Ban)",
"Sexual Harassment (Automatic Ban)",
"Spam, Bots, Fake/3rd Party Interaction (Automatic Ban)",
"State Verified Sex Offender (Automatic Ban)",
"Suicide or Self-Harm (contact upper management)",
"Troll accounts (Automatic Ban)",
"Underage (Automatic Ban)",
"Violence (Automatic Ban)",

    



  ];

  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel id="infraction-charge-label">Infraction Charge</InputLabel>
      <Select
        sx={inputStyles}
        labelId="infraction-charge-label"
        value={value}
        onChange={onChange}
      >
        {infractionCharges.map((charge, index) => (
          <MenuItem key={index} value={charge}>
            {charge}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InfractionChargeSelect;
