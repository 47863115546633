import { useState, useEffect, forwardRef, useCallback } from "react";
import {
  Box,
  useTheme,
  Snackbar,
} from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector, GridToolbarColumnsButton, gridClasses } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import axiosInstance from "../../api/axios";
import MuiAlert from "@mui/material/Alert";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { userRole } from "../../services/jwt";
import { Link } from "react-router-dom";

const USERS_URL = "api/app/appUsersData";
const UPDATE_USERS = "api/users/updateUserData";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Users = () => {
  const role = userRole();
  let col = '';
  const [data, setData] = useState([]);
  const [openField, setOpenField] = useState(false);



  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenField(false);
  };

    // USERS_URL
    useEffect(() => {
      const getAllUsers = async () => {
        try {
          const res = await axiosInstance.get(USERS_URL);
          setData(res.data);
        } catch (err) {
          console.log("error fetching all data =>", err);
        }
      };
      getAllUsers();
    }, []);

  const handleUpdated = useCallback(async (newRow) => {

    await axiosInstance
      .post(UPDATE_USERS, {
        _id: newRow._id,
        banned_at: newRow.banned_at,
        is_verified: newRow.is_verified,
        is_rising: newRow.is_rising,
        id_verification: newRow.id_verification,
        is_special_needs: newRow.is_special_needs,
        is_barter_market_available: newRow.is_barter_market_available
      })
      .then(async function (response) {
        setOpenField(true);
      })
      .catch(function (e) {
        console.log("Error registering", e);
      });
    const updateRow = { ...newRow };
    setData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item._id === newRow._id) {
          return { ...item, banned_at: newRow.banned_at, is_verified: newRow.is_verified,
            is_rising: newRow.is_rising,
            id_verification: newRow.id_verification,
            is_special_needs: newRow.is_special_needs,
            is_barter_market_available: newRow.is_barter_market_available };
        }
        return item;
      });

      return updatedData;
    });
    return updateRow;
  }, []);


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns =
    role === "csr" || role === "manager"
      ? [
          {
            field: "photo_url",
            headerName: "Avatar",
            width: 60,
            renderCell: params=><Avatar src={params.row.photo_url}/>,
            filterable: false,
            sortable: false,
          },
          {
            field: "username",
            headerName: "Username",
            width: 200,
            cellClassName: "name-column--cell",
            renderCell: (cellValues) => {
              return <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to={`/users-profile/${cellValues.row._id}`}>{cellValues.row.username}</Link>;
            }

          },
          {
            field: "status",
            headerName: "Status",
            width: 100,
            type: "boolean",
          },
          {
            field: "link",
            headerName: "Link",
            renderCell: (cellValues) => {
              return cellValues.row.link ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${cellValues.row.link}`}
                >
                  <OpenInNewOutlinedIcon sx={{ color: "#00CEC9" }} />
                </a>
              ) : (
                "N/A"
              );
            },
          },
          {
            field: "youtube_link",
            headerName: "Youtube Link",
            renderCell: (cellValues) => {
              return cellValues.row.youtube_link ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${cellValues.row.youtube_link}`}
                >
                  <OpenInNewOutlinedIcon sx={{ color: "#00CEC9" }} />
                </a>
              ) : (
                "N/A"
              );
            },
          },
          {
            field: "instagram_link",
            headerName: "Instagram Link",
            renderCell: (cellValues) => {
              return cellValues.row.instagram_link ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${cellValues.row.instagram_link}`}
                >
                  <OpenInNewOutlinedIcon sx={{ color: "#00CEC9" }} />
                </a>
              ) : (
                "N/A"
              );
            },
          },
          {
            field: "creator_type",
            headerName: "Creator Type",
            width: 200,
            renderCell: ({ row }) => {
              if (!row.creator_type) {
                return "N/A";
              }
              return row.creator_type;
            }
          },

          {
            field: "relationship_type",
            headerName: "Relationship Type",
            width: 200,
            renderCell: ({ row }) => {
              if (!row.relationship_type) {
                return "N/A";
              }
              return row.relationship_type;
            }
          },
          {
            field: "is_verified",
            type: "boolean",
            editable: true,
            headerName: "Verified",
          },
          {
            field: "acceptTerms",
            type: "boolean",
            editable: false,
            headerName: "TOS",
          },
          
          {
            field: "is_rising",
            type: "boolean",
            editable: true,
            headerName: "Rising Star",
            width: 80,
          },
          {
            field: "id_verification",
            type: "boolean",
            editable: true,
            headerName: "ID Verified",
            width: 80,
          },
          {
            field: "banned_at",
            type: "boolean",
            editable: true,
            headerName: "Banned Account",
            width: 200,
          },
          {
            field: "is_special_needs",
            type: "boolean",
            editable: true,
            headerName: "Is Special Needs",
            width: 200,
          },

          {
            field: "is_special_showing",
            type: "boolean",
            headerName: "Is Special Showing",
            width: 200,
          },
          {
            field: "disable_ticker",
            type: "boolean",
            headerName: "Disabled ticker",
            width: 200,
          },

          {
            field: "pronouns",
            headerName: "Pronouns",
            width: 200,
            renderCell: ({ row }) => {
              if (!row.pronouns) {
                return "N/A";
              }
              return row.pronouns;
            }
            
          },

          {
            field: "married_to",
            headerName: "Married to",
            width: 200,
            renderCell: ({ row }) => {
              if (!row.married_to) {
                return "N/A";
              }
              return row.married_to;
            }
          },

          {
            field: "is_account_private",
            type: "boolean",
            headerName: "Is Account Private",
            width: 200,
          },
          {
            field: "is_barter_market_available",
            type: "boolean",
            editable: true,
            headerName: "Is Market Available",
            width: 200,
          },
          
          {
            field: "created_at",
            type: "number",
            headerName: "Created at",
            width: 200,
            renderCell: (params) =>
              moment(params.row.created_at).format("YYYY-MM-DD"),
          },
          {
            field: "updated_at",
            type: "number",
            headerName: "Updated at",
            width: 200,
            renderCell: (params) =>
              moment(params.row.updated_at).format("YYYY-MM-DD"),
          },

          { field: "_id", headerName: "ID" },
        ]
      : [
          {
            field: "photo_url",
            headerName: "Avatar",
            width: 60,
            renderCell: params=><Avatar src={params.row.photo_url} />,
            filterable: false,
            sortable: false,
          },
          {
            field: "name",
            headerName: "Name",
            width: 200,
          },
          
          {
            field: "username",
            headerName: "Username",
            width: 200,
            cellClassName: "name-column--cell",
            renderCell: (cellValues) => {
              return <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to={`/users-profile/${cellValues.row._id}`}>{cellValues.row.username}</Link>;
            }

          },
          {
            field: "email",
            headerName: "Email",
            width: 200,
          },
          {
            field: "status",
            headerName: "Status",
            width: 100,
            type: "boolean",
          },
          {
            field: "link",
            headerName: "Link",
            renderCell: (cellValues) => {
              return cellValues.row.link ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${cellValues.row.link}`}
                >
                  <OpenInNewOutlinedIcon sx={{ color: "#00CEC9" }} />
                </a>
              ) : (
                "N/A"
              );
            },
          },
          {
            field: "youtube_link",
            headerName: "Youtube Link",
            renderCell: (cellValues) => {
              return cellValues.row.youtube_link ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${cellValues.row.youtube_link}`}
                >
                  <OpenInNewOutlinedIcon sx={{ color: "#00CEC9" }} />
                </a>
              ) : (
                "N/A"
              );
            },
          },
          {
            field: "instagram_link",
            headerName: "Instagram Link",
            renderCell: (cellValues) => {
              return cellValues.row.instagram_link ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${cellValues.row.instagram_link}`}
                >
                  <OpenInNewOutlinedIcon sx={{ color: "#00CEC9" }} />
                </a>
              ) : (
                "N/A"
              );
            },
          },
          {
            field: "creator_type",
            headerName: "Creator Type",
            width: 200,
            renderCell: ({ row }) => {
              if (!row.creator_type) {
                return "N/A";
              }
              return row.creator_type;
            }
          },

          {
            field: "relationship_type",
            headerName: "Relationship Type",
            width: 200,
            renderCell: ({ row }) => {
              if (!row.relationship_type) {
                return "N/A";
              }
              return row.relationship_type;
            }
          },
          {
            field: "is_verified",
            type: "boolean",
            editable: true,
            headerName: "Verified",
          },
          {
            field: "acceptTerms",
            type: "boolean",
            editable: false,
            headerName: "TOS",
          },
          {
            field: "is_rising",
            type: "boolean",
            editable: true,
            headerName: "Rising Star",
            width: 80,
          },
          {
            field: "id_verification",
            type: "boolean",
            editable: true,
            headerName: "ID Verified",
            width: 80,
          },
          {
            field: "banned_at",
            type: "boolean",
            editable: true,
            headerName: "Banned Account",
            width: 200,
          },

          {
            field: "is_special_needs",
            type: "boolean",
            editable: true,
            headerName: "Is Special Needs",
            width: 200,
          },

          {
            field: "is_special_showing",
            type: "boolean",
            headerName: "Is Special Showing",
            width: 200,
          },
          {
            field: "disable_ticker",
            type: "boolean",
            headerName: "Disabled ticker",
            width: 200,
          },

          {
            field: "pronouns",
            headerName: "Pronouns",
            width: 200,
            renderCell: ({ row }) => {
              if (!row.pronouns) {
                return "N/A";
              }
              return row.pronouns;
            }
          },

          {
            field: "married_to",
            headerName: "Married to",
            width: 200,
            renderCell: ({ row }) => {
              if (!row.married_to) {
                return "N/A";
              }
              return row.married_to;
            }
          },

          {
            field: "is_account_private",
            type: "boolean",
            headerName: "Is Account Private",
            width: 200,
          },
          {
            field: "is_barter_market_available",
            type: "boolean",
            editable: true,
            headerName: "Is Market Available",
            width: 200,
          },
          {
            field: "is_golden",
            type: "boolean",
            editable: true,
            headerName: "Is Gold Verified",
            width: 200,
          },
          {
            field: "created_at",
            type: "number",
            headerName: "Created at",
            width: 200,
            renderCell: (params) =>
              moment(params.row.created_at).format("YYYY-MM-DD"),
          },
          {
            field: "updated_at",
            type: "number",
            headerName: "Updated at",
            width: 200,
            renderCell: (params) =>
              moment(params.row.updated_at).format("YYYY-MM-DD"),
          },

          { field: "_id", headerName: "ID" },
        ];

  const rows = data.map((row) => ({
    photo_url: row.photo_url,
    name: row.name,
    username: row.username,
    email: row.email,
    status: row.status,
    link: row.link,
    youtube_link: row.youtube_link,
    instagram_link: row.instagram_link,
    creator_type: row.creator_type,
    relationship_type: row.relationship_type,
    is_verified: row.is_verified,
    acceptTerms: row.acceptTerms,
    is_rising: row.is_rising,
    id_verification: row.id_verification,
    banned_at: row.banned_at,
    is_special_needs: row.is_special_needs,
    is_special_showing: row.is_special_showing,
    disable_ticker: row.disable_ticker,
    pronouns: row.pronouns,
    married_to: row.married_to,
    is_account_private: row.is_account_private,
    is_barter_market_available: row.is_barter_market_available,
    created_at: row.created_at,
    updated_at: row.updated_at,
    is_golden: row.is_golden,
    _id: row._id,
  }));

  function CustomGridToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton/>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
        {role === "admin"? (
          <GridToolbarExport />
        ):null}
        
      </GridToolbarContainer>
    );
  }

  return (
    <Box m="20px">
      <Header title="Users" subtitle="Phlokk App" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={rows}
          getRowId={(row) => row._id}
          columns={columns}
          slots={{ toolbar: CustomGridToolbar}}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          onProcessRowUpdateError={() => {}}
          processRowUpdate={handleUpdated}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light"
                  ? colors.primary[100]
                  : colors.primary[700],
            },
          }}
        />
      </Box>
      <Snackbar
        open={openField}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          User account updated successfully
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Users;
